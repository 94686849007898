import React, {
  useCallback,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import { Shimmer } from "@my-scoot/component-library-legacy";
import {
  columnConfig,
  columnKeys,
  filterConfig,
  storeCustomerToSessionStorage,
  tableChipsFormatter,
} from "./TableConfig";
import ExlyTable from "common/Components/ExlyTable";
import moment from "moment";
import {
  DateConvert,
  getAnswerText,
  getIfSlotsAvailable,
  handleDateFormatV2,
  is_class,
} from "utils/Utils";
import { is_empty } from "utils/validations";
import dataProvider from "data/dataProvider";
import api from "data/APIs";
import Stats from "./Components/Stats";
import module_style from "../../Style.module.css";
import style from "../../../../../Style.module.css";
import constants from "constants/constants";
import { downloadCSV, useListController, useRefresh } from "react-admin";
import { getUserCurrencySymbol, getUserTimezone } from "utils/AuthUtil";
import { RESOURCE_KEYS, orgPermissions } from "utils/OrgPermissions";
import { notification_color_keys, useNotifications } from "utils/hooks";
import jsonExport from "jsonexport/dist";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { Tooltip } from "@my-scoot/component-library-legacy";
import {
  BookingQuestions,
  DetailsDrawerFooter,
  WhatsAppButton,
} from "webpage-leads/components/CustomColumns";
import {
  AppointmentDetails,
  ClassDetails,
  ReminderButton,
  ShareField,
  WorkshopDetails,
} from "./Components/TableFields/TableFields";
import { useToggleState } from "utils/hooks";
import CustomerTransactionsV2 from "../../../../../features/Crm/modules/Bookings/modules/CustomerTransactionsModal/CustomerTransactionsModal";
import { MIN_RENEWAL_THRESHOLD, form_data } from "./NewCustomers.constants";
import ExlyModal from "common/Components/ExlyModal";
import SessionStorageConstants from "constants/SessionStorage.constants";
import { listing_repeat_interval_types } from "schedule-v2/constants";
import localConstant from "./LocalConstant";
import {
  COMMUNITY_TABS,
  COMMUNITY_TABS_CONFIG,
  MANAGE_BOOKINGS_LEARN_MORE_HREFS,
  promotional_upsells,
  upsellTypeMap,
} from "../constants";
import UpsellStatsCard from "../ListingCustomers/components/UpsellStatsCard";
import { getFunnelStats } from "../components/helpers";
import {
  OFFERING_STATUSES,
  OFFERING_STATUS_ENUM,
  booking_record_slug,
  schedule_types_ids,
} from "constants/schedule";
import useCustomColumnsConfig from "utils/hooks/useCustomColumnsConfig";
import { bookings_section_name } from "ui/pages/schedule/BookedSession/bookedSession.constants";
import useOrgMemberList from "utils/hooks/useOrgMemberList";
import { apiMethods } from "data/api.constants";
import { logError } from "utils/error";
import {
  MoreActionsButton,
  PrimaryActionField,
} from "ui/pages/limitedOffer/components/CustomColumns";
import {
  PAYMENT_DEBIT_MODES,
  PAYMENT_DEBIT_MODES_LABELS,
} from "features/Listings/modules/PricingSection/PricingSection.constants";
import { fetchOfferingVariations } from "features/OfferingVariations/utils/OfferingVariations.utils";
import UpdateExpiryModal from "../../CustomerTransactionsV2/components/UpdateExpiryModal/UpdateExpiryModal";
import {
  getStatusHeaderName,
  getSubscriptionEndDateHeaderName,
  getSubscriptionStartDateHeaderName,
  updateExpiry,
} from "./SubscriptionCustomers.utils";
import {
  isClassType,
  isFlexibleOneTime,
  isQppSubscription,
} from "utils/listing/listingUtils";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import {
  AUTO_DEBIT_STATUS_TEXT,
  BOOKING_STATUS_ID,
  BOOKING_STATUS_MAP,
} from "constants/bookings.constants";
import useOfferingAutoDebitEnabled from "features/Listings/utils/useOfferingAutoDebitEnabled";
import { arrayHasOneElement } from "utils/object";
import { BULK_ACTION_TABLES } from "features/BulkActions/BulkActions.constants";
import BulkActionButton from "features/BulkActions/modules/BulkActionButton/BulkActionButton";
import SelectionCard from "features/BulkActions/modules/SelectionCard/SelectionCard";
import { BroadcastModal } from "common/Components/BroadcastModal/BroadcastModal";
import ExlyImage from "common/Components/ExlyImage";
import { BOOKING_LABEL } from "features/Crm/modules/Bookings/Bookings.constant";
import {
  getUtmInfoString,
  isGroupClassOneTime,
  isListingPlanTypeSubscription,
  isScheduleTypeListing,
} from "features/Listings/utils/Listings.utils";
import useCustomerDetails from "utils/hooks/useCustomerDetails";
import { listing_answer_count } from "features/Crm/Crm.constants";
import useBulkActions from "features/BulkActions/utils/hooks/useBulkActions";
import cssStyles from "./../../../../../features/Crm/modules/Bookings/BookingsStyles.module.css";
import AddCustomerModal from "features/Crm/modules/AddCustomerModal/components/AddCustomerModal";
import { Header } from "common/Components/Header";
import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import moduleStyles from "./SubscriptionCustomers.module.css";
import { EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS } from "features/Common/modules/ExlyEmptyState/constants/ExlyEmptyState.constants";
import { EXLY_TABLE_EMPTY_STATES_VERSIONS } from "common/Components/ExlyTable/ExlyTableEmptyState/constants/ExlyTableEmptyState.constants";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";
import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";
import {
  TELEGRAM_GROUP_STATE_ACTION_LABELS,
  TELEGRAM_GROUP_STATES,
} from "features/Crm/modules/Bookings/modules/ManageBookings/modules/TelegramBookings/modules/TelegramGroupState/TelegramGroupState.constants";
import {
  ShareLinkPopup,
  UserDetailsPopup,
} from "features/Crm/modules/Bookings/modules/ManageBookings/modules/TelegramBookings/TelegramBookings.lazy";
import { LazyLoadWrapper } from "features/Common/LazyLoadWrapper/LazyLoadWrapper";
import { showTelegramActions } from "features/Crm/modules/Bookings/modules/ManageBookings/modules/TelegramBookings/utils/TelegramBookings.utils";

const nextDueDateValue = ({ record = {}, source, return_string = false }) => {
  const isAutoDebitTypeSubs =
    record.payment_debit_mode === PAYMENT_DEBIT_MODES.AUTO_DEBIT;
  const { FORM_DATA, CANCELLED } = localConstant;
  if (is_empty(record[FORM_DATA.subscription_end_date])) {
    return "-";
  }
  const status = source !== FORM_DATA.status ? "_title" : "";

  const daysDiff = moment().diff(record["subscription_end_date"], "days");
  const minsDiff = moment().diff(record["subscription_end_date"], "minutes");

  let formatedString = "";
  // TODO: mayankGupta move this maths to the backend, handle the status accordingly, after refund release
  // doing this because, currently status is calculated on "subscription_end_date",
  // but if the refund is processed then "subscription_end_date" is not change because end date is not passed
  // so, right now by-passing the logic (calculated by "subscription_end_date")
  // add a condition if status === 3 (cancelled) and by-pass it.
  // @DEV Never spend time on this function (for creating conts, o following code convetions) , need to revamp it asap.

  if (record[source] === CANCELLED && !isAutoDebitTypeSubs) {
    formatedString = "Expired";
  } else if (source === FORM_DATA.status) {
    // This chunk is being added for auto-debit case (start)
    if (record[source] === BOOKING_STATUS_ID.failed) formatedString = "Failed";
    else if (record[source] === BOOKING_STATUS_ID.cancelled)
      formatedString = BOOKING_STATUS_MAP[BOOKING_STATUS_ID.cancelled]?.title;
    else if (record[source] === BOOKING_STATUS_ID.completed)
      formatedString = "Expired";
    // This chunk is being added for auto-debit case (end)
    else if (daysDiff <= -MIN_RENEWAL_THRESHOLD) {
      formatedString = "Active";
    } else if (
      daysDiff <= 0 &&
      minsDiff < 0 &&
      daysDiff > -MIN_RENEWAL_THRESHOLD
    ) {
      formatedString =
        record.course_choice != localConstant.ScheduleEndChoices.MONTHS
          ? "Active"
          : "Due";
    } else if (daysDiff >= 0 && minsDiff > 0 && daysDiff <= 28) {
      formatedString =
        record.course_choice != localConstant.ScheduleEndChoices.MONTHS
          ? "Expired"
          : "Overdue";
    } else {
      formatedString = "Expired";
    }
  } else {
    formatedString = DateConvert(record, FORM_DATA.subscription_end_date, true);
  }

  if (return_string) return formatedString;
  let tooltiopObject =
    localConstant.subscription_status_breakdown[
      record.course_choice != localConstant.ScheduleEndChoices.MONTHS
        ? "other"
        : "monthly"
    ];
  // This chunk is being added for auto-debit case (start)
  if (record.payment_debit_mode === PAYMENT_DEBIT_MODES.AUTO_DEBIT)
    tooltiopObject = AUTO_DEBIT_STATUS_TEXT;
  if (
    record[source] === BOOKING_STATUS_ID.completed ||
    record[source] === BOOKING_STATUS_ID.failed ||
    record[source] === BOOKING_STATUS_ID.cancelled
  ) {
    return (
      <Tooltip title={tooltiopObject[formatedString]} color="primary" arrow>
        <span className={`inactive_status${status}`}>{formatedString}</span>
      </Tooltip>
    );
  }
  // This chunk is being added for auto-debit case (end)

  if (record.course_choice != localConstant.ScheduleEndChoices.MONTHS) {
    // same handle this Cancelled check as define above
    if (minsDiff > 0 || record[source] === CANCELLED) {
      return (
        <Tooltip title={tooltiopObject[formatedString]} color="primary" arrow>
          <span className={`inactive_status${status}`}>{formatedString}</span>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={tooltiopObject[formatedString]} color="primary" arrow>
          <span className={`active_status${status}`}>{formatedString}</span>
        </Tooltip>
      );
    }
  }
  // same handle this Cancelled check as define above and remove this first if condition
  if (source === FORM_DATA.status && record[source] === CANCELLED) {
    return (
      <Tooltip title={tooltiopObject[formatedString]} color="primary" arrow>
        <span className={`inactive_status${status}`}>{formatedString}</span>
      </Tooltip>
    );
  } else if (daysDiff <= -5) {
    return (
      <Tooltip title={tooltiopObject[formatedString]} color="primary" arrow>
        <span className={`active_status${status}`}>{formatedString}</span>
      </Tooltip>
    );
  } else if (daysDiff <= 0 && minsDiff < 0 && daysDiff > -5) {
    return (
      <Tooltip title={tooltiopObject[formatedString]} color="primary" arrow>
        <span className={`due_status${status}`}>{formatedString}</span>
      </Tooltip>
    );
  } else if (daysDiff >= 0 && minsDiff > 0 && daysDiff <= 28) {
    return (
      <Tooltip title={tooltiopObject[formatedString]} color="primary" arrow>
        <span className={`over_due_status${status}`}>{formatedString}</span>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={tooltiopObject[formatedString]} color="primary" arrow>
        <span className={`inactive_status${status}`}>{formatedString}</span>
      </Tooltip>
    );
  }
};

const NewListingCustomers = (props) => {
  const {
    loaded,
    data,
    setFilters,
    selectedIds,
    filterValues,
    total,
    onSelect,
  } = useListController(props);
  const { orgMembers } = useOrgMemberList();
  const isEmpty = loaded && is_empty(data) && is_empty(filterValues);
  const isDesktop = useIsDesktop();
  const { notify } = useNotifications();
  const refetch = useRefresh();
  const { isOfferingAutoDebitEnabled: creatorAutoDebitEnabled } =
    useOfferingAutoDebitEnabled();
  const isTransactionsHidden = orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS,
    RESOURCE_KEYS.SECTIONS.MANAGE_TRANSACTIONS
  );

  const sendReminder = async (record) => {
    try {
      const status = await dataProvider.custom_request(
        `${api.send_renewal_reminder}`,
        apiMethods.POST,
        { transaction_uuid: record.transaction_uuid }
      );
      notify(status.message, notification_color_keys.success);
    } catch (err) {
      logError({
        error: err,
        occuredAt:
          "send reminder in src/ui/pages/customers/ManageBookings/SubscriptionCustomers/index.js",
        when: "Error while sending reminder",
      });
      notify(
        err?.body?.message || constants.error_message,
        notification_color_keys.error
      );
    }
  };

  const listing_customers_id = sessionStorage.getItem(
    SessionStorageConstants.LISTING_CUSTOMER_ID
  );
  const listing_customers_type = parseInt(
    sessionStorage.getItem(SessionStorageConstants.LISTING_CUSTOMER_TYPE)
  );
  const isNoSchedSub =
    JSON.parse(
      sessionStorage.getItem(
        SessionStorageConstants.LISTING_CUSTOMERS_IS_NO_SCHEDULE_SUBSCRIPTION
      )
    ) && listing_customers_type === constants.scheduleTypesId.no_schedule;

  const is_class_type = is_class({ type: listing_customers_type });
  const isGroupClass =
    listing_customers_type === constants.scheduleTypesId.group_class;
  const isAppointment =
    listing_customers_type === constants.scheduleTypesId.appointments;
  const isQuickPaymentPage =
    listing_customers_type === constants.scheduleTypesId.merchandise ||
    listing_customers_type === constants.scheduleTypesId.no_schedule;

  const isTelegramCommunity =
    listing_customers_type === constants.scheduleTypesId.telegram;

  // TODO States are not folllowing naming convention.
  const [listing_data, set_listing_data] = React.useState(null);
  const [offeringVariations, setOfferingVariations] = React.useState([]);
  const [loading, set_loading] = React.useState(true);
  const [show_add_modal, set_show_add_modal] = React.useState(false);
  const [show_answer_modal, set_show_answer_modal] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [showUpdateExpiryModal, setShowUpdateExpiryModal] = useState(false);
  const [appointments, set_appointments] = React.useState([]);
  const [show_more, set_show_more] = useState(false);
  const [show_modal, set_show_modal] = useState(false);
  const [slots_data, set_slots_data] = useState(null);
  const [upsellCardsArray, setUpsellCardsArray] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentSelectedRecord, setCurrentSelectedRecord] = useState({});

  // @dev - Telegram community specific
  const [shareLinkOpen, setShareLinkOpen] = useState(false);
  const [userDetailsOpen, setUserDetailsOpen] = useState(false);
  const [actionTransactionUuid, setActionTransactionUuid] = useState(null);

  const {
    isOnboardedPrevVal: isFeatureVisited,
    flagLoading: isFeatureFlagLoading,
    handleFeatureOnboardChange,
  } = useFeatureOnboardingChecker({
    featureKey: onboardingFeatureKeys.KNOWLEDGE_BASE.MANAGE_BOOKINGS,
    successCallback: (marked) => !marked && handleFeatureOnboardChange(true),
  });

  const [able_to_add, set_able_to_add] = React.useState(false);
  const [isTransactionModalActive, showTransactionModal, hideTransactionModal] =
    useToggleState(false);

  const onOpenTransactionModal = useCallback(
    (record) => {
      storeCustomerToSessionStorage(record);
      showTransactionModal();
    },
    [showTransactionModal]
  );

  const hasWriteAccess = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SUBSECTIONS.MY_BOOKINGS.BOOKINGS
  );
  const {
    getTableActions,
    showBroadcastModal,
    setShowBroadcastModal,
    handleRowsSelected,
    selectedRecords,
    initiateBroadcast,
    currentPageFullSelection,
    selectAll,
    getBroadcastActions,
    handleSelectAll,
    generateFooterProps,
  } = useBulkActions(props);

  const broadcastActions = getBroadcastActions({
    action: ({ tableName, broadcastType }) =>
      initiateBroadcast({
        tableName,
        broadcastType,
        listingUUID: listing_customers_id,
      }),
    tableName: BULK_ACTION_TABLES.SUBSCRIPTION_BOOKINGS,
  });

  const bulkActions = getTableActions(
    RESOURCE_KEYS.SUBSECTIONS.MY_BOOKINGS.BOOKINGS
  );

  const { handleBookingNavigationToCustomer } = useCustomerDetails();
  const { appendCustomTableConfig, getCustomColumnsToExport } =
    useCustomColumnsConfig({
      sectionName: bookings_section_name,
      recordSlug: booking_record_slug,
      setScrollPosition,
    });

  const { customColumnsToRender, customFiltersToAppend, customChipsToFormat } =
    appendCustomTableConfig({
      orgMembers,
      disabled: !hasWriteAccess,
    });

  const setAppointmentsData = (data) => {
    let temp = [];
    if (is_empty(data.slots)) return set_able_to_add(false);
    for (let i = 0; i < data.slots.length; i++) {
      let item = data.slots[i];
      let check = temp.filter(
        (slot) =>
          slot.date ===
          moment(item.start_time)
            .tz(getUserTimezone())
            .format(
              data.interval_type === listing_repeat_interval_types.weekly
                ? "dddd"
                : constants.displayDateYearFormat
            )
      );
      if (is_empty(check)) {
        temp.push({
          date: moment(item.start_time)
            .tz(getUserTimezone())
            .format(
              data.interval_type === listing_repeat_interval_types.weekly
                ? "dddd"
                : constants.displayDateYearFormat
            ),
          slots: [
            `${moment(item.start_time)
              .tz(getUserTimezone())
              .format("hh:mm A")} - ${moment(item.end_time)
              .tz(getUserTimezone())
              .format("hh:mm A")}`,
          ],
          index: temp.length,
        });
      } else {
        if (
          !temp[check[0].index].slots.includes(
            `${moment(item.start_time)
              .tz(getUserTimezone())
              .format("hh:mm A")} - ${moment(item.end_time)
              .tz(getUserTimezone())
              .format("hh:mm A")}`
          )
        ) {
          temp[check[0].index].slots.push(
            `${moment(item.start_time)
              .tz(getUserTimezone())
              .format("hh:mm A")} - ${moment(item.end_time)
              .tz(getUserTimezone())
              .format("hh:mm A")}`
          );
        }
      }
    }
    set_appointments(temp);
  };

  useEffect(() => {
    (async () => {
      try {
        const data = await dataProvider.custom_request(
          `${api.get_listing_details}/${listing_customers_id}`,
          "GET",
          { show_classes: "True", unique_customers: "True" }
        );
        if (data.status === 200) {
          let listingData = data.data.event;
          set_able_to_add(
            listingData.status ===
              OFFERING_STATUSES[OFFERING_STATUS_ENUM.live].value &&
              getIfSlotsAvailable(listingData) &&
              listingData.is_active
          );
          if (
            listing_customers_type === schedule_types_ids.one_on_one_appointment
          ) {
            setAppointmentsData(listingData);
          }
          await set_listing_data(listingData);

          if (listingData.is_offering_variants_enabled) {
            const offeringVariationsData = await fetchOfferingVariations({
              offeringUuid: listing_customers_id,
            });
            setOfferingVariations(
              offeringVariationsData?.data?.offering_variants
            );
          }
          set_loading(false);
        }

        const funnelStats = await getFunnelStats(listing_customers_id);
        setUpsellCardsArray(funnelStats);
      } catch (error) {
        logError({
          error,
          occuredAt:
            "src/ui/pages/customers/ManageBookings/SubscriptionCustomers/index.js",
          when: "did mount",
        });
      }
    })();
  }, []);

  useEffect(() => {
    /** if : scrollPosition
     * then : run window.scrollTo
     * why: when any record is updated from table ,  we need to scroll back to that record after refresh.
     */
    if (loaded && !is_empty(data) && scrollPosition)
      window.scrollTo(0, scrollPosition);
  }, [loaded, data]);

  const handleMore = async () => {
    await set_show_more(true);
    const listingDesElement = document.querySelector("#listing_desc_container");
    if (listingDesElement)
      listingDesElement.setAttribute(
        "style",
        "max-height: 260px; overflow: hidden;"
      );
  };

  useLayoutEffect(() => {
    if (
      document.querySelector("#listing_desc_container") &&
      !show_more &&
      document.querySelector("#listing_desc_container").offsetHeight > 260
    ) {
      handleMore();
    }
  });

  const renderSlots = () => {
    let week_data = [[], [], [], [], [], [], []];
    for (let item of slots_data) {
      let day = moment(item.slot_start_time)
        .tz(getUserTimezone())
        .format("dddd");
      week_data[form_data.week.indexOf(day)].push({
        time_range: [
          moment(item.slot_start_time).tz(getUserTimezone()).format("hh:mm A"),
          moment(item.slot_end_time).tz(getUserTimezone()).format("hh:mm A"),
        ],
        id: week_data[form_data.week.indexOf(day)].length + 1,
        date: moment(item.slot_start_time)
          .tz(getUserTimezone())
          .format(constants.displayDateYearFormat),
      });
    }

    return (
      <>
        <div style={{ marginBottom: 3 }} className={``}>{`Schedule`}</div>
        {week_data
          .filter((item) => !is_empty(item))
          .map((item) => (
            <div
              key={`schedule_${JSON.stringify(item)}`}
              style={{ marginBottom: 10 }}
            >
              <div
                style={{ marginBottom: 3 }}
                className={`${style.small_bold_grey}`}
              >
                {form_data.week[week_data.indexOf(item)]}
              </div>
              {item.map((slot) => (
                <div
                  key={`slot_${slot.id}`}
                  className={`${style.grey_small_text}`}
                >{`Slot ${slot.id} - ${slot.date} ${slot.time_range[0]} to ${slot.time_range[1]}`}</div>
              ))}
            </div>
          ))}
      </>
    );
  };

  const bookingsTitle = (
    <span className={module_style.page_title}>
      <span onClick={() => window.history.go(-1)} className="pointer">
        Bookings
      </span>
      {listing_data?.title && (
        <span>
          {` / `}
          <span className={module_style.action_text}>{listing_data.title}</span>
        </span>
      )}
    </span>
  );

  //@Dev(muskan) the exporter functionality will be moved to BE soon
  const exporter = (data) => {
    const dataForExport = data.map((data) => {
      const batch = listing_data.classes.filter(
        (item) => item.uuid == data[columnKeys.batch]
      );
      let return_data = {
        Name: data.customer_name,
        Email: data.customer_email,
        Phone: is_empty(data.customer_phone)
          ? "-"
          : `${data.customer_country_code} ${data.customer_phone}`,
        ...(isClass
          ? {
              [form_data.batch]: is_empty(batch)
                ? "Old batch"
                : `Batch ${listing_data.classes.indexOf(batch[0]) + 1}`,
            }
          : {}),
        ...(listing_data?.has_dropdown_plans
          ? { [form_data.plan_name]: data[columnKeys.planName] ?? "-" }
          : {}),
        ...(isOfferingAutoDebitEnabled
          ? {
              [form_data.payment_debit_mode]:
                PAYMENT_DEBIT_MODES_LABELS[data.payment_debit_mode],
            }
          : {}),
        [form_data.price]: `${getUserCurrencySymbol()} ${data.creator_sales}`,
        [form_data.booked_on]: data.booked_on
          ? handleDateFormatV2(data.booked_on)
          : "-",
        ...(isSubscriptionOrFlexibleOneTime
          ? {
              [getStatusHeaderName(listing_data)]: nextDueDateValue({
                record: data,
                source: columnKeys.status,
                return_string: true,
              }),
            }
          : {}),
        ...(isSubscriptionOrFlexibleOneTime
          ? {
              [getSubscriptionStartDateHeaderName(listing_data)]: data?.[
                columnKeys.subscriptionStartDate
              ]
                ? handleDateFormatV2(data?.[columnKeys.subscriptionStartDate])
                : "-",
            }
          : {}),
        ...(isSubscriptionOrFlexibleOneTime
          ? {
              [getSubscriptionEndDateHeaderName(listing_data)]:
                nextDueDateValue({
                  record: data,
                  source: columnKeys.subscriptionEndDate,
                  return_string: true,
                }),
            }
          : {}),
        [promotional_upsells.upsell_type]:
          upsellTypeMap[data.upsell_type] || "-",
        [promotional_upsells.source_upsell_offering_name]:
          data.source_upsell_offering_name || "-",
        [form_data.utm_info.placeholder]: getUtmInfoString({ record: data }),
        [listing_answer_count]: `${data?.answer_count || "-"}`,
        [form_data.subscription_count]: data.subscription_count,
        ...(isListingScheduleType ? { Timezone: data.customer_timezone } : {}),
        ...getCustomColumnsToExport(data),
      };
      if (data?.answer_count > 0 && !is_empty(data.answer_list)) {
        for (let item of data.answer_list) {
          if (item.question_text in return_data) continue;
          return_data[item.question_text] = getAnswerText(item);
        }
      }
      return return_data;
    });
    jsonExport(dataForExport, (err, csv) => {
      downloadCSV(csv, "Listing Customers"); // download as 'posts.csv` file
    });
  };

  const canAddCustomers = able_to_add && hasWriteAccess;
  const canSendEmail = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
  );
  const canSendWhatsapp = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.WHATSAPP_BROADCASTS
  );
  const isCustomerDetailsHidden = orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SECTIONS.ALL_CUSTOMERS
  );

  const isClass = isClassType(listing_data?.type);
  const isListingScheduleType = isScheduleTypeListing(listing_data?.type);
  const isSubscriptionListing = isListingPlanTypeSubscription(listing_data);
  const isSubscriptionOrFlexibleOneTime =
    isSubscriptionListing || isFlexibleOneTime(listing_data);
  const isOfferingAutoDebitEnabled =
    creatorAutoDebitEnabled && isSubscriptionListing;
  const showAddCustomerAction = canAddCustomers && !isEmpty;
  const canBroadcastMessage = (canSendEmail || canSendWhatsapp) && !isEmpty;
  const showActions = showAddCustomerAction || canBroadcastMessage;

  let _firstBulkAction = {
    text: "Add Customer",
    onClick: () => set_show_add_modal(true),
    hide: !showAddCustomerAction,
    icon: (
      <ExlyImage
        src="./assets/vectors/crm/person_add.svg"
        height={20}
        width={20}
        id="tag"
      />
    ),
  };
  const footerProps = generateFooterProps({
    bulkActions,
    primaryFooterProps: _firstBulkAction,
  });

  const getMoreActions = (record) => {
    const primaryActions = [];
    const isAutoDebitTypeSubs =
      record.payment_debit_mode === PAYMENT_DEBIT_MODES.AUTO_DEBIT;
    const isGroupClassOneTimeListing = isGroupClassOneTime(listing_data);
    if (!isGroupClassOneTimeListing && !isAutoDebitTypeSubs) {
      primaryActions.push({
        label: "Send Renewal Reminder",
        onClick: () => sendReminder(record),
      });
      if (isFlexibleOneTime(listing_data) || isQppSubscription(listing_data))
        primaryActions.push({
          label: isQppSubscription(listing_data)
            ? "Change Subscription Cycle"
            : "Change Validity",
          onClick: () => {
            setSelectedRecord(record);
            setShowUpdateExpiryModal(true);
          },
        });
    }

    if (!isCustomerDetailsHidden) {
      primaryActions.push({
        label: "View Activity",
        onClick: () => {
          onOpenTransactionModal(record);
        },
      });
    }

    if (showTelegramActions(isTelegramCommunity, record, true)) {
      primaryActions.push({
        label:
          TELEGRAM_GROUP_STATE_ACTION_LABELS[record.tg_link_status] ||
          TELEGRAM_GROUP_STATE_ACTION_LABELS.DEFAULT,
        onClick: () => {
          setActionTransactionUuid(record.transaction_uuid);
          // @dev - If user has already joined the telegram group then show the joined user details else show joining link
          if (record.tg_link_status === TELEGRAM_GROUP_STATES.JOINED) {
            setUserDetailsOpen(true);
          } else {
            setShareLinkOpen(true);
          }
        },
      });
    }

    // TODO:MADHAV will find better solution later, currently urgent fixx
    if (!isDesktop && arrayHasOneElement(primaryActions))
      primaryActions.push({});

    return primaryActions;
  };

  const modalTitle = (
    <div>
      <div style={{ marginBottom: 5, fontWeight: 600, fontSize: 16 }}>
        {listing_data?.title}
      </div>
      <div style={{ marginBottom: 5, fontSize: 14 }}>
        {constants.schedule_type[listing_data?.type]?.name}
      </div>
    </div>
  );

  const isCommunity =
    listing_data?.type === schedule_types_ids.branded_community;

  const handleAnswersClick = useCallback(
    (record) => {
      setSelectedRecord(record);
      set_show_answer_modal(true);
    },
    [setSelectedRecord, set_show_answer_modal]
  );

  useEffect(() => {
    return () => onSelect([]); //@Dev need to clear the selectedIds when moved to another route
  }, []);

  return (
    <div className={module_style.listing_modal_contaner}>
      {isDesktop && (
        <Header
          title={bookingsTitle}
          right={
            <div className="d-flex">
              <LearnMoreCta
                href={MANAGE_BOOKINGS_LEARN_MORE_HREFS.MANAGE_BOOKINGS}
              />
              {canAddCustomers && (
                <BulkActionButton
                  actionItem={_firstBulkAction}
                  isSmall={false}
                  className="ml-3"
                />
              )}
            </div>
          }
          classNames={{ container: moduleStyles.headerContainer }}
        />
      )}

      <div
        className={`component-wrapper ${
          module_style.listing_detail_container
        } ${!isDesktop && "mb-3"}`}
      >
        <div className={`d-flex`}>
          <img
            className={`${module_style.offering_image} mr-4`}
            src={listing_data?.cover_image}
          />
          {loading ? (
            <div>
              <Shimmer
                width="105px"
                height="22px"
                wrapper_classname={module_style.mb_12}
                borderRadius="12px"
              />
              <Shimmer
                width="194px"
                height="12px"
                wrapper_classname={module_style.mb_12}
                borderRadius="12px"
              />
              <Shimmer width="99px" height="12px" borderRadius="12px" />
            </div>
          ) : (
            <div className={` ${module_style.listing_container}`}>
              <div
                id="listing_desc_container"
                className={` ${module_style.listing_desc_container}`}
              >
                <div style={{ marginBottom: 5, fontWeight: 600, fontSize: 16 }}>
                  {listing_data?.title}
                </div>
                <div style={{ marginBottom: 5, fontSize: 14 }}>
                  {constants.schedule_type[listing_data?.type]?.name}
                </div>
                {listing_customers_type === 3 ? (
                  <WorkshopDetails listingData={listing_data} />
                ) : listing_customers_type === 0 ? (
                  <AppointmentDetails appointments={appointments} />
                ) : is_class_type || isNoSchedSub ? (
                  <ClassDetails listingData={listing_data} />
                ) : null}
              </div>
              {show_more && (
                <div
                  onClick={() => set_show_modal(true)}
                  className={`link_text ${module_style.more}`}
                >
                  More...
                </div>
              )}
            </div>
          )}
        </div>
        <div className={module_style.cardsWrapper}>
          {upsellCardsArray?.map((cardValues) => (
            <UpsellStatsCard
              Icon={cardValues.Icon}
              key={cardValues.title}
              statsNumber={cardValues.statsNumber}
              title={cardValues.title}
              color={cardValues.color}
              onCardClick={cardValues.onClick}
              tooltipString={cardValues.tooltipString}
            />
          ))}
        </div>
      </div>
      <div className={isDesktop && "component-wrapper-x"}>
        <div className={`mt-3 ${!isDesktop && "mb-3"}`}>
          {/* @DEV,need to show stats only for subscription type offerings (This table is also used for group class and rolling class one time payment but subscription stats should not be visible for them)*/}
          {isListingPlanTypeSubscription(listing_data) && (
            <Stats
              {...props}
              listing_uid={listing_customers_id}
              setFilters={setFilters}
            />
          )}
        </div>

        <ExlyTable
          ra_props={{
            ...props,
            exporter: orgPermissions.canExport() ? exporter : false,
          }}
          entityName={BOOKING_LABEL}
          showSelectAllcheckBox
          bulkActionComponent={
            <SelectionCard
              actions={bulkActions}
              selectedRecords={Object.keys(selectedRecords)}
              totalRecords={total}
              currentPageFullSelection={currentPageFullSelection}
              entityName={BOOKING_LABEL}
              selectAll={selectAll}
              handleSelectAll={handleSelectAll}
            />
          }
          allValuesSelected={selectAll}
          tabConfig={
            isCommunity ? COMMUNITY_TABS_CONFIG(listing_data) : undefined
          }
          selectedTab={isCommunity ? COMMUNITY_TABS.COMMUNITY.value : undefined}
          noExportButton={!orgPermissions.canExport()}
          tableTitle={!isDesktop && bookingsTitle}
          layoutProps={{
            paddingDesktop: "0",
            paddingBottom: "120px",
            layoutDesktopMargin: "20px 0px 0px 0px",
            layoutMobileMargin: "0",
            paddingMobile: "0px",
            noMobileBoxShadow: true,
            learnMoreHref: MANAGE_BOOKINGS_LEARN_MORE_HREFS.MANAGE_BOOKINGS,
          }}
          primaryActions={getMoreActions(currentSelectedRecord)}
          primaryColumnProps={{
            align: "center",
            sticky: true,
            title: "Action",
            className: cssStyles.actionColumnStyle,
          }}
          columnConfig={columnConfig({
            nextDueDateValue,
            isCustomerDetailsHidden,
            handleAnswersClick,
            customColumnsToRender,
            showVariationColumn: !is_empty(offeringVariations),
            listing_data,
            isOfferingAutoDebitEnabled,
            isTransactionsHidden,
            isClass,
            isTelegramCommunity,
            isSubscriptionOrFlexibleOneTime,
            handleBookingNavigationToCustomer,
          })}
          renderPrimaryColumnRightNode={(record) =>
            !isCustomerDetailsHidden && (
              <WhatsAppButton
                record={record}
                source="customer_phone"
                countryCodeKey="customer_country_code"
              />
            )
          }
          renderDrawerHeaderNode={
            (is_class_type || isNoSchedSub) &&
            ((record) => {
              return <ShareField record={record} isGroupClass={isGroupClass} />;
            })
          }
          tableFilters={filterConfig({
            listingData: listing_data,
            isNoSchedSub,
            customFiltersToAppend,
            offeringVariations,
            isOfferingAutoDebitEnabled,
            isClass,
            isListingScheduleType,
            isSubscriptionOrFlexibleOneTime,
          })}
          tableChipsFormatter={(filter, value) =>
            tableChipsFormatter({
              filter,
              value,
              options: { isNoSchedSub },
              customChipsToFormat,
              orgMembers,
              offeringVariations,
              listingData: listing_data,
            })
          }
          hasMobileFooter={showActions}
          footerProps={footerProps}
          borderedFields
          drawerFieldsBordered
          fieldsAlignment="space-between"
          drawerFieldsAlignment="space-between"
          fieldsLeftPadded
          recordFooterVariant="secondary"
          primaryKey="id"
          checkboxSelection
          onRowSelected={handleRowsSelected}
          selected={selectedIds}
          desktopCustomPrimaryAction={{
            renderCustomAction: (row, rows, setAnchorEl, setOpenPopper) => (
              <PrimaryActionField
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                  setOpenPopper(true);
                  setCurrentSelectedRecord(row);
                }}
              />
            ),
          }}
          customPrimaryAction={{
            renderCustomAction: (
              record,
              rows,
              setDrawerConfig,
              setActionsDrawerConfig
            ) => (
              <MoreActionsButton
                label="More Actions"
                onClick={() => {
                  setCurrentSelectedRecord(record);
                  setActionsDrawerConfig({ row: record, open: true });
                }}
              />
            ),
            onClose: (row, rows, setDrawerConfig, setActionsDrawerConfig) => {
              setActionsDrawerConfig((prevState) => ({
                ...prevState,
                open: false,
              }));
            },
          }}
          renderDrawerFooter={(record) =>
            isGroupClass ? (
              <div className={module_style.drawerFooter}>
                <ReminderButton record={record} />
              </div>
            ) : (
              <DetailsDrawerFooter
                record={record}
                source="customer_phone"
                countryCodeKey="customer_country_code"
              />
            )
          }
          renderSecondaryAction={(record) => {
            if (isAppointment)
              return (
                <div className="font_400">
                  <span className="font_600">Timezone:</span>
                  {` ${record?.customer_timezone || "-"}`}
                </div>
              );
            if (is_class_type || isNoSchedSub)
              return nextDueDateValue({ record, source: "status" });
            if (isQuickPaymentPage)
              return <div className="font_600">Check Answers:</div>;
            return <div />;
          }}
          getRecordFooterClassName={() => {
            if (
              isAppointment ||
              is_class_type ||
              isNoSchedSub ||
              isQuickPaymentPage ||
              !isCustomerDetailsHidden
            )
              return "";
            return "d-none";
          }}
          emptyStateProps={{
            isLoading: isFeatureFlagLoading,
            version: EXLY_TABLE_EMPTY_STATES_VERSIONS.V2,
            title: "No Offering Customers yet.",
            description:
              "As soon as we have a new offering customer, it will show up here. View the video to learn more.",
            videoSrc: MANAGE_BOOKINGS_LEARN_MORE_HREFS.MANAGE_BOOKINGS_VIDEO,
            playerProps: {
              playing: !isFeatureFlagLoading && !isFeatureVisited, // autoplay the knowledge base video on the first visit to this feature
            },
            primaryCtaProps: {
              title: "Add Customer",
              onClick: () => set_show_add_modal(true),
            },
            secondaryCtaProps: {
              variant: EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS.learn_more,
              learnMoreHref: MANAGE_BOOKINGS_LEARN_MORE_HREFS.MANAGE_BOOKINGS,
            },
            className: "mb-5",
          }}
        />
      </div>

      <ExlyModal
        open={show_modal}
        title={modalTitle}
        onClose={() => {
          set_show_modal(false);
          set_slots_data(null);
        }}
        modal_props={{
          customFooter: <></>,
          modalPaperClassName: module_style.desktop_detail_modal,
          dialogTitleClassName: module_style.detail_modal_header,
        }}
        mobile_modal_props={{
          customFooter: <></>,
          dialogHeaderClassName: module_style.mobile_detail_header,
        }}
        mobile_wrap_class="py-3"
        desktop_wrap_class="py-3"
      >
        {!is_empty(slots_data) ? (
          renderSlots()
        ) : listing_customers_type === 3 ? (
          <WorkshopDetails listingData={listing_data} fullWidth noBorder />
        ) : listing_customers_type === 0 ? (
          <AppointmentDetails appointments={appointments} fullWidth noBorder />
        ) : (
          <ClassDetails listingData={listing_data} fullWidth noBorder />
        )}
      </ExlyModal>

      {canBroadcastMessage && showBroadcastModal && (
        <BroadcastModal
          open={showBroadcastModal}
          onClose={() => setShowBroadcastModal(false)}
          modal_props={{ title: "Send Message" }}
          mobile_modal_props={{ header: "Select Broadcast medium" }}
          broadcastActions={broadcastActions}
        />
      )}

      {isTransactionModalActive && (
        <CustomerTransactionsV2
          open={isTransactionModalActive}
          onClose={hideTransactionModal}
          countryCodeKey="customer_country_code"
        />
      )}
      {show_add_modal && (
        <AddCustomerModal
          offeringVariations={offeringVariations}
          open={show_add_modal}
          onClose={() => {
            set_show_add_modal(false);
          }}
          listing_data={listing_data}
          showOfferingSelector={false}
        />
      )}

      {show_answer_modal && (
        <BookingQuestions
          isOpen={show_answer_modal}
          data={selectedRecord}
          hideModal={() => {
            set_show_answer_modal(false);
          }}
          title="Booking Q&A"
        />
      )}

      {showUpdateExpiryModal && !is_empty(selectedRecord) && (
        <UpdateExpiryModal
          showStartDate={isQppSubscription(listing_data)}
          open={showUpdateExpiryModal}
          endDate={selectedRecord.subscription_end_date}
          modalTitle={
            isQppSubscription(listing_data) ? "Change subscription cycle" : null
          }
          modalSubTitle={
            isQppSubscription(listing_data)
              ? `Change subscription cycle of ${listing_data.title} for ${selectedRecord.customer_name}`
              : null
          }
          endDateLabel={
            isFlexibleOneTime(listing_data) ? "End Date" : undefined
          }
          startDate={
            isQppSubscription(listing_data)
              ? selectedRecord.subscription_start_date
              : null
          }
          onClose={() => setShowUpdateExpiryModal(false)}
          onSave={(newEndDate, newStartDate) => {
            updateExpiry({
              record: selectedRecord,
              newEndDate,
              newStartDate,
              onSuccess: (response) => {
                setShowUpdateExpiryModal(false);
                refetch();
                notify(response.message, notification_color_keys.success);
              },
              onFailure: (error) => {
                notify(
                  error?.body?.message ?? SOMETHING_WENT_WRONG,
                  notification_color_keys.error
                );
              },
            });
          }}
          sessionName={listing_data.title}
          customerName={selectedRecord.customer_name}
        />
      )}

      {shareLinkOpen && actionTransactionUuid && (
        <LazyLoadWrapper
          loadingCondition={shareLinkOpen && actionTransactionUuid}
        >
          <ShareLinkPopup
            isOpen={shareLinkOpen}
            onClose={() => {
              setShareLinkOpen(false);
              setActionTransactionUuid(null);
            }}
            transactionUuid={actionTransactionUuid}
          />
        </LazyLoadWrapper>
      )}

      {userDetailsOpen && actionTransactionUuid && (
        <LazyLoadWrapper
          loadingCondition={userDetailsOpen && actionTransactionUuid}
        >
          <UserDetailsPopup
            isOpen={userDetailsOpen}
            onClose={() => {
              setUserDetailsOpen(false);
              setActionTransactionUuid(null);
            }}
            transactionUuid={actionTransactionUuid}
          />
        </LazyLoadWrapper>
      )}
    </div>
  );
};

export default withComponentLibraryTheme(NewListingCustomers);
